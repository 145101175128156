<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Study Abroad Admin: List</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAdmin" icon @click="showFilters=!showFilters">
        <v-icon>{{ filtersActive ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="showFilters">
      <v-row>
        <v-col :cols="12" md="4">
          <v-select v-model="statusFilter" :items="statusOptions" label="Status Filter" hide-details outlined dense></v-select>
        </v-col>
        <v-col :cols="12" md="4">
          <v-select v-model="termFilter" :items="termOptions" label="Term" multiple hide-details outlined dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="termFilter.length <= 3 && index <= 2" small close>
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="termFilter.length === termOptions.length && index === 0" class="grey--text caption">All {{ termFilter.length }} Terms Selected</span>
              <span v-else-if="termFilter.length > 3 && index === 0" class="grey--text caption">
                {{ termFilter.length }} Terms Selected
              </span>
            </template>
            <template v-slot:prepend-item>
              <v-list-item ripple @click="selectAllTerms">
                <v-list-item-action>
                  <v-icon :color="termFilter.length > 0 ? 'primary' : ''">
                    {{ termFilter.length === 0 ? 'far fa-square' : (termFilter.length === termOptions.length ? 'fas fa-check-square' : 'fas fa-minus-square') }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ termFilter.length === termOptions.length ? 'Deselect All' : 'Select All' }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="12" md="4">
          <v-select v-model="majorFilter" :items="majorOptions" label="Major" item-value="text" multiple hide-details outlined dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="majorFilter.length <= 3 && index <= 2" small close @click:close="majorFilter.splice(index, 1)">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-else-if="majorFilter.length > 3 && index === 0" class="grey--text caption">
                {{ majorFilter.length }} Majors Selected
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="12" md="4">
          <v-select v-model="partnerFilter" :items="partnerOptions" label="Partner" multiple hide-details outlined dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="partnerFilter.length <= 3 && index <= 2" small close @click:close="partnerFilter.splice(index, 1)">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-else-if="partnerFilter.length > 3 && index === 0" class="grey--text caption">
                {{ partnerFilter.length }} Partners Selected
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="12" md="4">
          <v-select v-model="locationFilter" :items="locationOptions" label="Location" multiple hide-details outlined dense>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="locationFilter.length <= 3 && index <= 2" small close @click:close="locationFilter.splice(index, 1)">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-else-if="locationFilter.length > 3 && index === 0" class="grey--text caption">
                {{ locationFilter.length }} Locations Selected
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col :cols="12" md="4">
          <v-text-field v-model="nameFilter" label="Name" hide-details outlined dense></v-text-field>
        </v-col>
        <v-col>
          <v-btn text @click="showFilters = false">Hide Filters</v-btn>
          <v-btn text @click="clearFilters">Clear Filters</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="apps" :headers="headers" :server-items-length="appCount" :footer-props="{ 'items-per-page-options': [5,10,15,20,30,50] }" @update:options="load" @dblclick:row="(e, { item }) => $router.push('/student/study-abroad/admin/' + item._id)">
      <template v-slot:item.majors="{ item }">{{ item.majors.join(', ') }}</template>
      <template v-slot:item.submittedDate="{ item }">{{ stringFormatDate(item.submittedDate) }}</template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon :to="'/student/study-abroad/admin/' + item._id" v-on="on">
              <v-icon v-if="item.submitted && checkApproval(item)" color="success">fal fa-check</v-icon>
              <v-icon v-else-if="item.submitted">fal fa-eye</v-icon>
              <v-icon v-else>fal fa-pause-circle</v-icon>
            </v-btn>
          </template>
          <span v-if="item.submitted && checkApproval(item)">Approved; Click to access application</span>
          <span v-else-if="item.submitted">Review this application</span>
          <span v-else>Student has started but not submitted their application.</span>
        </v-tooltip>
        <v-btn icon :to="'/student/study-abroad/admin/' + item._id + '#messages'" v-if="getUnreadMessageCount(item)">
          <v-badge :content="getUnreadMessageCount(item)" color="error" overlap>
            <v-icon>fal fa-envelope</v-icon>
          </v-badge>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate } from '../../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => 'Academic Affairs' in root.$store.state.roles || 'Records' in root.$store.state.roles)
    const apps = ref([])
    const appCount = ref(0)
    const headers = ref([
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Name', value: 'name' },
      { text: 'Major(s)', value: 'majors' },
      { text: 'Term', value: 'programTerm' },
      { text: 'Grad Year', value: 'gradYear' },
      { text: 'Partner', value: 'partner' },
      { text: 'Location', value: 'location' },
      { text: 'Submitted', value: 'submittedDate' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions' }
    ])

    const service = root.$feathers.service('student/study-abroad')

    const showFilters = computed({
      get: () => root.$store.state.student.studyAbroad.showFilter,
      set: (show) => root.$store.commit('student/setStudyAbroadShowFilter', show)
    })
    const statusFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.status,
      set: (status) => root.$store.commit('student/setStudyAbroadFilter', { status })
    })
    // Default the term Filter to all future terms (can be changed by admins, but departmental approvers can only see future terms, for now at least)
    const termFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.term,
      set: (term) => root.$store.commit('student/setStudyAbroadFilter', { term })
    })
    const majorFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.major,
      set: (major) => root.$store.commit('student/setStudyAbroadFilter', { major })
    })
    const partnerFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.partner,
      set: (partner) => root.$store.commit('student/setStudyAbroadFilter', { partner })
    })
    const locationFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.location,
      set: (location) => root.$store.commit('student/setStudyAbroadFilter', { location })
    })
    const nameFilter = computed({
      get: () => root.$store.state.student.studyAbroad.filter.name,
      set: (name) => root.$store.commit('student/setStudyAbroadFilter', { name })
    })
    const filtersActive = computed(() => {
      return statusFilter.value !== '' || termFilter.value.length > 0 || majorFilter.value.length > 0 || nameFilter.value !== ''
    })
    const statusOptions = ref([
      { text: 'All Active', value: '' },
      { text: 'Started, Not Submitted', value: 'Unsubmitted' },
      { text: 'Submitted, Pending Approval', value: 'Pending' },
      { text: 'Returned to Student', value: 'Returned' },
      { text: 'Approved', value: 'Approved' },
      { text: 'Cancelled', value: 'Cancelled' },
      { text: 'Archived', value: 'Archived' }
    ])
    const termOptions = ref([])
    const majorOptions = ref([])
    const partnerOptions = ref([])
    const locationOptions = ref([])

    function load (options) {
      console.log('Loading...')
      if (options == null) {
        options = {
          itemsPerPage: 10,
          page: 1,
          sortBy: [],
          sortDesc: []
        }
      }

      const { username, roles } = user.value
      if (roles.filter((role) => role === 'Health & Counseling Services').length > 0) {
        switch (username) {
          case 'kim.ball':
          case 'kendra.keith':
          case 'aaron.reed':
            roles.push('Health Services')
            break
          default:
            roles.push('PriestHill Counseling Services')
        }
      }
      let query = { approvals: { $elemMatch: { approved: false, $or: [ { username }, { department: { $in: roles } } ] } } }
      if (isAdmin.value) {
        query = { }
      }
      if (termFilter.value.length > 0) {
        query.$or = [{ programTerm: { $in: termFilter.value } }, { term: { $in: termFilter.value } }]
      } else if (!isAdmin.value) {
        const terms = termOptions.value.map(({ value }) => value)
        query.$or = [{ programTerm: { $in: terms } }, { term: { $in: terms } }]
      }
      let { page, itemsPerPage, sortBy, sortDesc } = options
      query.$limit = itemsPerPage
      query.$skip = (page - 1) * itemsPerPage
      query.$sort = {}
      if (sortBy.length > 0) {
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      } else {
        query.$sort = {
          submittedDate: -1
        }
      }
      if (statusFilter.value === '') {
        query.status = { $in: ['Unsubmitted', 'Pending', 'Returned', 'Approved'] }
      } else {
        query.status = statusFilter.value
      }
      if (majorFilter.value.length > 0) {
        query.majors = { $in: majorFilter.value }
      }
      if (partnerFilter.value.length > 0) {
        query.partner = { $in: partnerFilter.value }
      }
      if (locationFilter.value.length > 0) {
        query.location = { $in: locationFilter.value }
      }
      if (nameFilter.value !== '') {
        query.name = { $regex: nameFilter.value, $options: 'i' }
      }
      service.find({ query }).then(({ data, total }) => {
        apps.value = data.map((row) => {
          if (!('programTerm' in row)) {
            row.programTerm = row.term
          }
          return row
        })
        appCount.value = total
      })
    }
    watch([statusFilter, termFilter, majorFilter, partnerFilter, nameFilter], () => { load() })

    function getUnreadMessageCount (app) {
      const username = user.value.username
      let unreadMessages = 0
      for (let i = 0; i < app.approvals.length; i++) {
        if (app.approvals[i].department in root.$store.state.roles || (app.approvals[i].department === 'Advisor' && app.approvals[i].username === username)) {
          if ('messages' in app.approvals[i] && app.approvals[i].messages.length > 0) {
            const messages = app.approvals[i].messages
            for (let j = 0; j < messages.length; j++) {
              let isRead = false
              if ('readUsername' in messages[j]) {
                for (let k = 0; k < messages[j].readUsername.length; k++) {
                  if (messages[j].readUsername[k] === username) isRead = true
                }
              }
              if (!isRead) unreadMessages++
            }
          }
        }
      }
      return unreadMessages
    }

    function checkApproval (app) {
      const username = user.value.username
      for (let i = 0; i < app.approvals.length; i++) {
        if (app.approvals[i].department in root.$store.state.roles || (app.approvals[i].department === 'Advisor' && app.approvals[i].username === username)) {
          return app.approvals[i].approved
        }
      }
      return false
    }

    const onPatchedListener = async (data) => { load() }
    onMounted(async () => {
      service.on('created', onPatchedListener)
      service.on('patched', onPatchedListener)
      service.on('updated', onPatchedListener)

      root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } }).then(({ data }) => {
        root.$feathers.service('forms/select-options').get(data[0].value).then(({ options }) => {
          majorOptions.value = options
        })
      })
      const { data: partnerData } = await service.find({ query: { aggregate: [{ $group: { _id: '$partner', count: { $sum: 1 } } }, { $project: { partner: '$_id', count: 1 } }, { $sort: { partner: 1 } }] } })
      partnerOptions.value = partnerData.map(({ partner, count }) => { return { text: (partner || '--No Partner--') + ' (' + count + ' apps)', value: partner } })
      const { data: locationData } = await service.find({ query: { aggregate: [{ $group: { _id: '$location', count: { $sum: 1 } } }, { $project: { location: '$_id', count: 1 } }, { $sort: { location: 1 } }] } })
      locationOptions.value = locationData.map(({ location, count }) => { return { text: (location || '--No Location--') + ' (' + count + ' apps)', value: location } })
      const aggregate = [
        { $project: {
          programTerm: { $ifNull: ['$programTerm', '$term', ''] }
        } },
        { $match: { programTerm: { $ne: '' } } },
        { $group: { _id: '$programTerm', count: { $sum: 1 } } },
        { $project: {
          term: '$_id',
          count: 1,
          year: { $substr: ['$_id', { $subtract: [{ $strLenCP: '$_id' }, 4] }, 4] },
          termText: { $substr: ['$_id', 0, { $subtract: [{ $strLenCP: '$_id' }, 5] }] }
        } },
        { $project: {
          term: 1,
          count: 1,
          code: { $concat: ['$year', { $switch: {
            branches: [
              { case: { $eq: ['$termText', 'Fall'] }, then: '60' },
              { case: { $eq: ['$termText', 'Spring'] }, then: '05' },
              { case: { $eq: ['$termText', 'Summer'] }, then: '50' }
            ]
          } }] }
        } },
        { $sort: { code: -1 } }
      ]
      const { data: termData } = await service.find({ query: { aggregate } })
      termOptions.value = termData.map(({ term, count }) => { return { text: term + ' (' + count + ' apps)', value: term } })
      load()
    })
    onBeforeUnmount(() => {
      service.removeListener('created', onPatchedListener)
      service.removeListener('patched', onPatchedListener)
      service.removeListener('updated', onPatchedListener)
    })

    function selectAllTerms () {
      if (termFilter.value.length < termOptions.value.length) {
        termFilter.value = []
        for (const obj of termOptions.value) termFilter.value.push(obj)
      } else {
        termFilter.value = []
      }
    }

    function clearFilters () {
      statusFilter.value = ''
      termFilter.value = []
      majorFilter.value = []
      partnerFilter.value = []
      locationFilter.value = []
      nameFilter.value = ''
    }

    return {
      user,
      isAdmin,
      apps,
      appCount,
      headers,
      getUnreadMessageCount,
      checkApproval,
      showFilters,
      statusFilter,
      termFilter,
      majorFilter,
      partnerFilter,
      locationFilter,
      nameFilter,
      filtersActive,
      statusOptions,
      termOptions,
      majorOptions,
      partnerOptions,
      locationOptions,
      load,
      selectAllTerms,
      clearFilters,
      stringFormatDate
    }
  }
}
</script>
